/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="--center pb--30 pt--30" name={"uvod"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pt--0 flex--center" anim={null} style={{"maxWidth":""}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s4 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"4"} style={{"maxWidth":850}}>
              
              <Title className="title-box title-box--invert pb--0 pt--0" style={{"maxWidth":""}} content={"Kontakt"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kontakt"} style={{"paddingTop":50,"paddingBottom":0,"backgroundColor":"var(--white)"}}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5" anim={"5"}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/965c3e40d27d46d385ed085e1b11d2fa.svg"} sizes={"100vw"} style={{"maxWidth":70}} srcSet={""} svgColor={"rgba(0,92,183,1)"}>
              </Image>

              <Subtitle className="subtitle-box mt--10" content={"+420 734 148 215<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4" anim={"4"}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/6b72aab19c4f4d1ca38a5ca397b2ec1c.svg"} sizes={"100vw"} style={{"maxWidth":70}} srcSet={""} svgColor={"rgba(0,92,183,1)"}>
              </Image>

              <Subtitle className="subtitle-box mt--10" content={"<a href=\"mailto:uklidnaklik@gmail.com?subject=Popt%C3%A1vka+z+webu\">uklidnaklik@gmail.com</a><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"sidlo"} style={{"paddingTop":58}}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="--shape4 js-anim  --anim2 --center pb--20 pl--12 pr--12 pt--20" anim={"2"} style={{"backgroundColor":"var(--color-custom-1)"}}>
              
              <Subtitle className="subtitle-box fs--22 mt--10" content={"<span style=\"color: var(--white); font-weight: bold;\">Sídlo firmy</span><br>"}>
              </Subtitle>

              <Text className="text-box lh--12" style={{"marginTop":21}} content={"<span style=\"color: var(--color-custom-1--95);\">Všestary 173 <br>503 12 <br>Všestary u Hradce Králové</span>"}>
              </Text>

              <Text className="text-box fs--18 w--600" content={"<span style=\"color: var(--white);\">Obchodní informace:</span><br>"}>
              </Text>

              <Text className="text-box lh--14" style={{"marginTop":2}} content={"<span style=\"color: var(--color-custom-1--95);\">IČO: 07195702 <br>DIČO: CZ07195702 <br>Vedená u Městského soudu v Hradci Králové</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"paticka"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Divider className="mt--20">
              </Divider>

              <Title className="title-box title-box--center fs--36" content={"<span style=\"font-weight: bold;\"><br>Kontakt:</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center fs--20" content={"<span style=\"color: var(--color-supplementary);\"><span style=\"font-weight: bold;\">+420</span> <span style=\"font-weight: bold;\">734 148 215</span></span><br>"}>
              </Text>

              <Text className="text-box text-box--center fs--20" style={{"marginTop":0}} content={"<a href=\"mailto:uklidnaklik@gmail.com?subject=Popt%C3%A1vka+z+webu\" style=\"font-weight: bold; color: var(--color-supplementary);\">uklidnaklik@gmail.com</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}